import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {graphql, Link} from "gatsby";
import PostList from "../components/postList";
import * as styles from "./category.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faHome} from "@fortawesome/free-solid-svg-icons"

export const pageQuery = graphql`
    query($slug: String!) {
        allMicrocmsPost(
            sort: { fields: [publishedAt], order: DESC }
            filter: { category: { slug: { eq: $slug } } }
        ) {
            posts: edges {
                node {
                    postId
                    title
                    body
                    eyecatch {
                        url
                    }
                    category {
                        name
                        slug
                    }
                    publishedAt(formatString: "YYYY/MM/DD")
                    updatedAt
                }
            }
        }
    }
`

const CategoryPage = ({data, pageContext}) => {
    const {category} = pageContext
    return (
        <Layout>
            <Seo title={category.name}/>
            <nav className="breadcrumb">
                <ol>
                    <li>
                        <Link to="/"><FontAwesomeIcon icon={faHome}/></Link>
                    </li>
                    <li>
                        <Link to={`/${category.slug}`}>{category.name}</Link>
                    </li>
                </ol>
            </nav>
            <h2 className={styles.title}>「{category.name}」の記事</h2>
            <div className={styles.postList}>
                <PostList posts={data.allMicrocmsPost.posts.map(post => post.node)}/>
            </div>
        </Layout>
    )
}

export default CategoryPage